import React from 'react';
import {
  getColorbyLevel,
  getRandomColor,
} from '../../../screens/PreliminaryReportScreen/helpers/getRandomColorClass';

import { translateFields } from './helpers/transletor';

const PhrasesList = ({ phrasesData, respData, type }) => {
  const PhrasesListRender = () => {
    if (type === 'wallet') {
      if (respData.lengt !== 0) {
        return (
          <div className="flex flex-col gap-[4px] h-[300px] overflow-scroll">
            {respData.map((field, index) => {
              if (
                field.type_label === 'Undefined' ||
                field.type_label === 'undefined'
              ) {
                return <></>;
              }
              const percent = field.total_count;
              const { background, circle } = getColorbyLevel(
                field.risk_score_grade,
              );

              return (
                <div
                  className="flex items-center justify-between px-3 py-[10px] text-[14px] rounded-lg"
                  style={{ backgroundColor: background }}
                  key={index}
                >
                  <div className="flex items-center gap-1 ">
                    <span
                      className="w-2 h-2 rounded-full bg-red-700"
                      style={{
                        width: 8,
                        height: 8,
                        backgroundColor: circle,
                      }}
                    />
                    <span className="">
                      {translateFields[field.type_label] || field.type_label}
                    </span>
                  </div>
                  <span>{Math.floor(percent)}%</span>
                </div>
              );
            })}
          </div>
        );
      } else {
        return (
          <div className="flex flex-col gap-[4px] h-[300px] overflow-scroll">
            <p>Кошелька не существует либо данные не корректны</p>
          </div>
        );
      }
    } else {
      return (
        <div className="flex flex-col gap-[4px] h-[300px] overflow-scroll">
          {phrasesData.map((phrase, index) => {
            const { background, circle } = getRandomColor();
            return (
              <div
                className="flex items-center justify-between px-3 py-[10px] text-[14px] rounded-lg"
                style={{ backgroundColor: background }}
                key={index}
              >
                <div className="flex items-center gap-1 ">
                  <span
                    className="w-2 h-2 rounded-full bg-red-700"
                    style={{
                      width: 8,
                      height: 8,
                      backgroundColor: circle,
                    }}
                  />
                  <span className="">{phrase}</span>
                </div>
                <span>0%</span>
              </div>
            );
          })}
        </div>
      );
    }
  };
  return PhrasesListRender();
};

export default PhrasesList;
