import React from 'react';

const ToggleButton = ({ buttonColor, handlePlaceholderChange }) => {
  return (
    <div className="bg-[#AE2EB5] rounded-full p-[2px]">
      <div className="w-full h-[66px] rounded-full p-[2px] bg-[#1c1e37] flex">
        <button
          className="w-1/2 h-full rounded-full font-bold text-white transition-colors duration-300 ease-in-out"
          onClick={() => handlePlaceholderChange('T')}
          style={{ backgroundColor: buttonColor.T }}
        >
          Кошелек
        </button>
        <button
          className="w-1/2 h-full rounded-full font-bold text-white transition-colors duration-300 ease-in-out"
          onClick={() => handlePlaceholderChange('S')}
          style={{ backgroundColor: buttonColor.S }}
        >
          Смарт-контракт
        </button>
      </div>
    </div>
  );
};

export default ToggleButton;
