const colors = [
  {
    circle: '#DACD45',
    background: '#F9F7E1',
  },
  {
    circle: '#4BB73D',
    background: '#E2F3E0',
  },
  {
    circle: '#FA504C',
    background: '#FEE3E2',
  },
];

export const getColorbyLevel = (level) => {
  console.log(level);
  switch (level) {
    case 'low':
      return colors[0];
    case 'moderate':
      return colors[1];
    case 'high':
      return colors[2];
    default:
      return colors[2];
  }
};

export const getRandomColor = () => {
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};
