import React from 'react';

const riskOptions = [
  {
    key: 'fine',
    title: 'Нормально',
    color: '#4BB73D',
  },
  {
    key: 'suspicious',
    title: 'Подозрительный',
    color: '#DACD45',
  },
  {
    key: 'risky',
    title: 'Рискованный',
    color: '#FA504C',
  },
];

const RiskList = () => {
  return (
    <div className=" pt-3 px-[28px]">
      <ul className="flex justify-between">
        {riskOptions.map(({ color, key, title }) => (
          <li key={key} className="flex items-center text-[12px] gap-1">
            <span
              className="w-2 h-2 rounded-full"
              style={{ backgroundColor: color }}
            />
            <span>{title}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default RiskList;
