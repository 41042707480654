import axios from 'axios';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://scam-back.taskcomplete.ru',
});

export const v1api = axios.create({
  baseURL: 'https://explorer.coinkyt.com/openapi/',
});

export const mscapi = axios.create({
  baseURL: 'https://scam-pay.taskcomplete.ru/',
});
