import React from 'react';
import UpperLogo from '../../video/logo.mp4';
import RiskAssessment from '../../components/RiskAssessment/RiskAssessment';

const PreliminaryReportScreen = ({
  setLoading,
  setIsBought,
  riskLevel,
  phrases,
  address,
  loading,
  isError,
  setIsError,
  setAddress,
}) => {
  return (
    <div className="flex flex-col h-screen items-center gap-3">
      <div className="container-upperlogo">
        <video
          className="loader-container-video"
          autoPlay
          muted
          loop
          playsInline
        >
          <source src={UpperLogo} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>{' '}
      <RiskAssessment
        phrases={phrases}
        address={address}
        riskLevel={riskLevel}
        setLoading={setLoading}
        loading={loading}
        isError={isError}
        setIsBought={setIsBought}
        setIsError={setIsError}
        setAddress={setAddress}
        type={'contract'}
      />
    </div>
  );
};

export default PreliminaryReportScreen;
