import React, { useState } from 'react';
import styles from './TextInput.module.css';
import clsx from 'clsx';
import Close from '../../../images/close.png';

const TextInput = ({
  onChange,
  onFocus,
  onBlur,
  value = '',
  label,
  type = 'text',
  placeholder,
  readOnly,
  icon,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleChange = (e) => {
    onChange?.(e.target.value);
  };

  const isIPhone = () => {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
  };

  const handleFocus = (e) => {
    if (isIPhone()) {
      setIsExpanded(true);
    }
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    onFocus?.(e.currentTarget.value);
  };

  const handleBlur = (e) => {
    if (isIPhone()) {
      setIsExpanded(false);
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
    onBlur?.(e.currentTarget.value);
  };

  return (
    <div
      className={styles.container}
      style={{ height: isExpanded ? '50vh' : 'auto' }}
    >
      {label && (
        <div className={styles.label}>
          <span>{label}</span>
        </div>
      )}
      <div
        className={clsx(
          styles.inputContainer,
          icon && value && 'flex justify-between gap-2',
        )}
        style={{ height: 64 }}
      >
        <input
          value={value}
          onChange={handleChange}
          type={type}
          placeholder={placeholder}
          className={styles.input}
          disabled={readOnly}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />

        {value && (
          <button
            className="flex justify-center items-center rounded-full w-12 h-10 bg-[#3D3F58] mr-4"
            onClick={() => onChange?.('')}
          >
            <img src={Close} alt="close" />
          </button>
        )}
      </div>
    </div>
  );
};

export default TextInput;
