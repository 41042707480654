import { useState, useEffect } from 'react';
import UpperLogo from './video/logo.mp4';
import Speedometer from './components/speedometer/Speedometer';
import { subscribe } from '@telegram-apps/sdk-react';
import './App.css';
import MainScreen from './screens/MainScreen/MainScreen';
import PreliminaryReportScreen from './screens/PreliminaryReportScreen/PreliminaryReportScreen';

const App = () => {
  const [selectedCurrency, setSelectedCurrency] = useState('btc');
  const [isChecked, setIsChecked] = useState(false);
  const [riskLevel, setRiskLevel] = useState(null);
  const [phrases, setPhrases] = useState([]);
  const [isBought, setIsBought] = useState(false);
  const [loading, setLoading] = useState(false);
  const [report, setReport] = useState('');
  const [address, setAddress] = useState('');
  const [isByPage, setIsByPage] = useState(false);
  const [isError, setIsError] = useState(false);
  const [respData, setRespDta] = useState([]);

  const [showSpeedometer, setShowSpeedometer] = useState(false);
  const [speedometerValue, setSpeedometerValue] = useState(0);

  useEffect(() => {
    const unsubscribe = subscribe((event) => {
      if (event.name === 'invoice_closed' && event.payload.status === 'paid') {
        // Логика при успешной оплате
      }
    });

    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <>
      {showSpeedometer ? (
        <div className="speed-container">
          <div className="container-upperlogo">
            <video
              className="loader-container-video"
              style={{ marginTop: '30px' }}
              autoPlay
              muted
              loop
              playsInline
            >
              <source src={UpperLogo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>

          <Speedometer
            selectedCurrency={selectedCurrency}
            respData={respData}
            percent={speedometerValue}
            phrase={phrases}
            phrases={phrases}
            riskLevel={riskLevel}
            setIsBought={setIsBought}
            setLoading={setLoading}
            loading={loading}
            address={address}
            isBought={isBought}
            isError={isError}
            setIsError={setIsError}
            setAddress={setAddress}
          />
        </div>
      ) : !isChecked ? (
        <MainScreen
          isError={isError}
          setIsBought={setIsBought}
          isBought={isBought}
          setSelectedCurrency={setSelectedCurrency}
          selectedCurrency={selectedCurrency}
          respData={respData}
          setRespDta={setRespDta}
          setIsChecked={setIsChecked}
          setLoading={setLoading}
          setPhrases={setPhrases}
          setReport={setReport}
          setRiskLevel={setRiskLevel}
          setSpeedometerValue={setSpeedometerValue}
          setShowSpeedometer={setShowSpeedometer}
          address={address}
          setAddress={setAddress}
          setIsByPage={setIsByPage}
          loading={loading}
          setIsError={setIsError}
        />
      ) : (
        isByPage && (
          <PreliminaryReportScreen
            phrases={phrases}
            riskLevel={riskLevel}
            setIsBought={setIsBought}
            setLoading={setLoading}
            loading={loading}
            address={address}
            isBought={isBought}
            isError={isError}
            setIsError={setIsError}
            setAddress={setAddress}
          />
        )
      )}
    </>
  );
};

export default App;
