import React from 'react';
import Spinner from '../../UI/Loaders/Spinner/Spinner';

const LoadingView = () => {
  return (
    <div className="text-center text-white">
      <Spinner />
      <h2 className="text-[32px] font-bold">Ожидаем оплату</h2>
    </div>
  );
};

export default LoadingView;
