import React, { useEffect, useState, useRef } from 'react';
import TextInput from '../../components/UI/TextInput/TextInput';
import ToggleButton from '../../components/UI/Buttons/ToggleButton/ToggleButton';
import { Button } from '../../components/UI/Buttons/Button/Button';
import UpperLogo from '../../video/logo.mp4';
import { validateAddress } from '../../helpers/ValidateAddress';
import { api, v1api } from '../../api/api';
import { ErrorMessages } from '../../constants/errorsMessages';
import { staticLabels, staticPlaceholders } from './constants';
import Clip from '../../video/clip.gif';
import LowerLogoImg from '../../images/LowerLogo.png';
import Select from '../../components/UI/Select/Select.jsx';
import BuyView from '../../components/RiskAssessment/components/BuyView.jsx';
import CardModal from '../../components/UI/Modals/CardModal/CardModal.jsx';
import LoadingView from '../../components/RiskAssessment/components/LoadingView.jsx';
import { useTonConnectUI } from '@tonconnect/ui-react';
import AnswerView from '../../components/RiskAssessment/components/AnswerView.jsx';
import { mscapi } from '../../api/api.js';

import { initUtils } from '@telegram-apps/sdk';
const utils = initUtils();

const tonTransaction = {
  validUntil: Math.floor(new Date() / 1000) + 360,
  messages: [
    {
      address: 'UQCaBOLhebDJqCnzQMuns1UNirBJQXAwq9OUYqKtVJw5jJT8',
      amount: (0.3 * 1e9).toString(),
    },
  ],
};

const MainScreen = ({
  setSelectedCurrency,
  selectedCurrency,
  setRespDta,
  setShowSpeedometer,
  setLoading,
  setIsChecked,
  setSpeedometerValue,
  setRiskLevel,
  setReport,
  setPhrases,
  address,
  setAddress,
  setIsByPage,
  loading,

  isError,
  setIsError,
  isBought,
  setIsBought,
}) => {
  const [buttonColor, setButtonColor] = useState({ T: '#AE2EB5', S: '' });
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenTon, setIsOpenTonBtn] = useState(false);
  const [tonConnectUI] = useTonConnectUI();
  const [isSuccess, setIsSuccess] = useState(false);

  const [uMoneyUrl, setUMoneyUrl] = useState('');
  const prevUrl = useRef(null);
  const [uMoneyId, setUMoneyId] = useState('');

  const [inputInfo, setInputInfo] = useState({
    placeholder: staticPlaceholders.wallet,
    label: staticLabels.wallet,
    isWallet: true,
  });

  const cloleModal = () => {
    setIsOpenModal(false);
    setIsOpenTonBtn(false);
  };

  const repeatTry = () => {
    setIsError(false);
    setIsBought(false);
  };

  useEffect(() => {
    if (prevUrl.current !== uMoneyUrl && uMoneyUrl) {
      utils.openLink(uMoneyUrl);
      prevUrl.current = uMoneyUrl;
    }
  }, [uMoneyUrl]);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      if (!loading) {
        clearInterval(intervalId);
        return;
      }

      try {
        const response = await mscapi.get(`/status/${uMoneyId}`);

        if (response.data.status === 'succeeded') {
          setLoading(false);
          clearInterval(intervalId);
          setIsBought(true);
          handleSubmit();
        }
      } catch (error) {
        console.error('Ошибка при получении статуса:', error);
      }
    }, 4000);
  }, [uMoneyId]);

  const handleBuy = async () => {
    setLoading(true);
    // try {
    //   const response = await axios.get(
    //     process.env.REACT_APP_API_URL + 'bot/invoice_link',
    //     {
    //       address,
    //     },
    //   );
    //   setLoading(false);
    //   const data = response.data;
    //   postEvent('web_app_open_invoice', {
    //     slug: data.invoice_link.split('$')[1],
    //   });
    // } catch (error) {
    //   console.error('Error fetching data:', error);
    // }

    try {
      tonConnectUI.sendTransaction(tonTransaction).then(() => {
        setIsSuccess(true);
        setIsBought(true);
      });
    } catch (error) {
      alert('Ошибка при отправке транзакции:', error);
    }
    setLoading(false);
  };

  const handlePlaceholderChange = (type) => {
    if (type === 'T') {
      setInputInfo({
        label: staticLabels.wallet,
        placeholder: staticPlaceholders.wallet,
        isWallet: true,
      });
      setButtonColor({ T: '#AE2EB5', S: '' });
    } else {
      setInputInfo({
        label: staticLabels.address,
        placeholder: staticPlaceholders.address,
        isWallet: false,
      });
      setButtonColor({ T: '', S: '#AE2EB5' });
    }
    setShowSpeedometer(false);
  };

  const handleSubmit = async () => {
    // e.preventDefault();
    if (!validateAddress(address)) {
      alert(ErrorMessages.ERROR_ADDRESS);
      return;
    }
    if (!isBought) {
      setIsOpenModal(true);
      return;
    }
    setLoading(true);
    setIsChecked(false);

    if (inputInfo.placeholder === staticPlaceholders.wallet) {
      try {
        const response = await v1api.get(
          `v1/address?blockchain=${selectedCurrency.toLowerCase()}&address=${address}`,
          {
            headers: {
              Accept: 'application/json',
              'X-API-Key': '8b8140ff-eaaf-4ef0-98ce-d623014a2ba1',
            },
          },
        );
        if (response.data.indirects.length !== 0) {
          setRespDta(...[response.data.indirects]);
          setRiskLevel(Math.floor(response.data.risk_score * 100));
        } else if (response.data.directs.length !== 0) {
          setRespDta(...[response.data.directs]);
          setRiskLevel(Math.floor(response.data.risk_score * 100));
        } else {
          setRespDta(...[]);
          setRiskLevel(0);
        }

        setSpeedometerValue(response.data.vol);
        setLoading(false);
        setShowSpeedometer(true);
      } catch (error) {
        alert(
          'По данному адрессу нет кошелька, проверьте правильно ли выбрали сеть',
        );
        setLoading(false);
      }
    } else {
      try {
        const response = await api.post('input/request', {
          address,
        });
        const data = response.data;
        setLoading(false);
        setRiskLevel(data.percent);
        setReport(data.answer);
        const parsedPhrases = data.short
          .split('\n')
          .filter((item) => item.trim() !== '')
          .map((item) => item.replace(/^\d+\.\s*/, ''));
        if (
          parsedPhrases[parsedPhrases.length - 1].startsWith('Процент риска:')
        ) {
          parsedPhrases.pop();
        }

        const firstThreePhrases = parsedPhrases.slice(0, 3);

        setPhrases(firstThreePhrases);
        setIsChecked(true);
        setIsByPage(true);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    }
  };

  if (loading) {
    return (
      <div className="loader-container">
        <div className="container-upperlogo">
          <video
            className="loader-container-video"
            autoPlay
            muted
            loop
            playsInline
          >
            <source src={UpperLogo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div>
          <img
            className="loader-container-video"
            src={Clip}
            alt="Loading animation"
            style={{ display: 'block', margin: '0 auto' }}
          />
        </div>
        <div className="container-buttons">
          <img src={LowerLogoImg} alt="Logo" />
        </div>
      </div>
    );
  }

  return (
    <div className="h-screen w-screen flex flex-col items-center justify-between px-4 py-[50px] overflow-y-auto">
      <div className="flex flex-col items-center gap-[50px] w-full">
        <div className="bg-[#1c1e37]">
          <video
            autoPlay
            muted
            loop
            playsInline
            className="bg-[#1c1e37] h-[170px] w-[200px]"
          >
            <source src={UpperLogo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div className="w-full flex flex-col gap-5">
          <span className="font-bold text-white text-start text-[30px]">
            Выберите, <br /> что хотите проверить
          </span>

          <ToggleButton
            buttonColor={buttonColor}
            handlePlaceholderChange={handlePlaceholderChange}
          />
          {inputInfo.isWallet && (
            <Select
              setSelectedCurrency={setSelectedCurrency}
              selectedCurrency={selectedCurrency}
            />
          )}

          <TextInput
            label={inputInfo.label}
            placeholder={inputInfo.placeholder}
            onChange={setAddress}
            value={address}
            type="text"
          />
        </div>
      </div>
      <Button onClick={handleSubmit}>
        {isBought ? 'Провести анализ' : 'Оплатить'}
      </Button>

      <CardModal isOpen={isOpenModal} closeModal={cloleModal}>
        {loading ? (
          <LoadingView />
        ) : !isBought ? (
          <BuyView
            handleBuy={handleBuy}
            setIsOpenTonBtn={setIsOpenTonBtn}
            isOpenTon={isOpenTon}
            loading={loading}
            setLoading={setLoading}
            setUMoneyUrl={setUMoneyUrl}
            setUMoneyId={setUMoneyId}
          />
        ) : (
          <AnswerView
            handleDownload={handleSubmit}
            isError={isError}
            repeatTry={repeatTry}
          />
        )}
      </CardModal>
      {isSuccess && (
        <CardModal>
          <div className="text-center text-white">
            <h2 className="text-[32px] font-bold mb-[56px]">
              Успешно оплачено
            </h2>
          </div>
        </CardModal>
      )}
    </div>
  );
};

export default MainScreen;
