import styles from './Select.module.css';

const Select = ({ selectedCurrency, setSelectedCurrency }) => {
  const handleClick = (event) => {
    setSelectedCurrency(event.target.value);
  };

  return (
    <div className={styles.container}>
      <div className={styles.label}>
        <span>Выберите сеть</span>
      </div>

      <select
        value={selectedCurrency}
        onChange={handleClick}
        className={styles.select}
      >
        <option className={styles.coin} value="btc">
          BTC
        </option>
        <option className={styles.coin} value="eth">
          ETH
        </option>
        <option className={styles.coin} value="trx">
          TRX
        </option>
      </select>
    </div>
  );
};

export default Select;
