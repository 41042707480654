import clsx from 'clsx';
import Spinner from '../../../../components/UI/Loaders/Spinner/Spinner';

export const Button = ({
  type = 'button',
  className,
  children,
  disabled,
  isLoading,
  onClick,
}) => {
  return (
    <button
      className={clsx(
        disabled && 'bg-opacity-65',
        'relative h-[55px] px-4 py-6 w-full flex items-center justify-center rounded-xl min-w-[55px] transition-transform duration-300 font-medium bg-[#AE2EB5] text-white ',
        className,
      )}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      {isLoading ? <Spinner /> : children}
    </button>
  );
};
