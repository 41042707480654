import React from 'react';
import { Button } from '../../UI/Buttons/Button/Button';
import Success from '../../../images/success.png';
import Error from '../../../images/error.png';

const AnswerView = ({ isError, repeatTry, handleDownload }) => {
  return (
    <div className="flex flex-col items-center text-center text-white">
      {isError ? (
        <>
          <img src={Error} alt="Ошибка" width={80} />
          <h2 className="text-[32px] font-bold mb-[56px]">Ошибка оплаты</h2>
          <Button onClick={repeatTry}>Повторить</Button>
        </>
      ) : (
        <>
          <img src={Success} alt="Успешно" width={80} />
          <h2 className="text-[32px] font-bold mb-[56px]">Оплата прошла</h2>
          <Button onClick={handleDownload}>Оk</Button>
        </>
      )}
    </div>
  );
};

export default AnswerView;
