import React, { useMemo } from 'react';
import Logo from '../../images/efirLogo.png';
import PhrasesList from './components/PhrasesList';
import RiskList from './components/RiskList';

const RiskAssessment = ({ respData, phrases, address, riskLevel, type }) => {
  const RiskAssessmentRender = () => {
    const phrasesData = useMemo(() => phrases, []);
    if (type === 'wallet') {
      return (
        <section className=" relative rew-full h-full bg-white rounded-tr-xl rounded-tl-xl py-4">
          <div className="px-4">
            <div className="flex items-center gap-[1opx] font-semibold text-[12px] ">
              <img src={Logo} alt="Логотип Efir" />
              <span>{address}</span>
            </div>
            <div className="flex items-center pb-3 gap-3 text-[24px] mt-3">
              <span>Оценка риска:</span>
              <span className="font-bold text-[#FA504C]">{riskLevel}% </span>
            </div>
          </div>
          <div className="h-[1px] w-screen bg-[#D9D9D9]" />
          <RiskList />
          <div className="flex flex-col  px-4 mt-6">
            <PhrasesList
              phrasesData={phrasesData}
              respData={respData}
              type={type}
            />
          </div>
        </section>
      );
    } else if (type === 'contract') {
      return (
        <section className=" relative rew-full h-full bg-white rounded-tr-xl rounded-tl-xl py-4">
          <div className="px-4">
            <div className="flex items-center gap-[1opx] font-semibold text-[12px] ">
              <img src={Logo} alt="Логотип Efir" />
              <span>{address}</span>
            </div>
            <div className="flex items-center pb-3 gap-3 text-[24px] mt-3">
              <span>Оценка риска:</span>
              <span className="font-bold text-[#FA504C]">{riskLevel}% </span>
            </div>
          </div>
          <div className="h-[1px] w-screen bg-[#D9D9D9]" />
          <RiskList />
          <div className="flex flex-col  px-4 mt-6">
            <PhrasesList phrasesData={phrasesData} />
          </div>
        </section>
      );
    }
  };
  return RiskAssessmentRender();
};
export default RiskAssessment;
