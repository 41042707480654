import React from 'react';
import { monetItems } from '../../UI/Modals/CardModal/constants';
import { TonConnectButton, useTonWallet } from '@tonconnect/ui-react';
import { Button } from '../../UI/Buttons/Button/Button';
import { mscapi } from '../../../api/api';
import cn from 'classnames';

const BuyView = ({
  handleBuy,
  setIsOpenTonBtn,
  isOpenTon,
  loading,
  setLoading,
  setUMoneyUrl,
  setUMoneyId,
}) => {
  const wallet = useTonWallet();

  const handlePayRuble = async () => {
    if (loading) return;

    try {
      setLoading(true);
      const response = await mscapi.post(
        '/payment',
        { amount: '89.00' },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      setUMoneyUrl(response.data.confirmation.confirmation_url);
      setUMoneyId(response.data.id);
    } catch (error) {
      console.error('Ошибка при выполнении запроса:', error);
    }
  };

  const handlePayment = (itemName, event) => {
    event.preventDefault();
    switch (itemName) {
      case '₽':
        return handlePayRuble();
      case 'TON':
        return setIsOpenTonBtn((prev) => !prev);
      default:
        return () => {};
    }
  };

  return (
    <div className="text-center text-white">
      {loading ? (
        <div className="flex justify-center items-center h-[200px]">
          Ждем пока пройдет платеж ...
        </div>
      ) : isOpenTon ? (
        <div className="flex flex-col items-center gap-[40px] justify-center h-[200px]">
          <TonConnectButton />
          <Button onClick={handleBuy} disabled={!wallet}>
            Купить
          </Button>
        </div>
      ) : (
        <>
          <h2 className="text-[32px] font-bold">Покупка</h2>
          <p className="mb-4 text-white">
            Daily Value Package <br />
            <span className="text-[32px] font-bold text-white">= $0.99</span>
          </p>

          <div className="space-y-2 mb-4 flex flex-col items-stretch">
            {monetItems.map((item, index) => {
              return (
                <button
                  onClick={(event) => handlePayment(item.name, event)}
                  className="flex justify-center gap-3 p-5 bg-[#1C1C36] rounded-lg"
                >
                  <img src={item.icon} alt="pict" />
                  <div
                    className={cn({
                      'text-gray-800': item.name === '₽' && loading,
                    })}
                  >
                    <span>{item.count}</span>
                    <span>{item.name}</span>
                  </div>
                </button>
              );
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default BuyView;
