import React, { useEffect } from 'react';

const CardModal = ({ children, closeModal, isOpen }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, []);

  return (
    <div
      className={`fixed inset-0 flex justify-center bg-black  items-top py-2 transition-opacity px-2 visible z-50 bg-opacity-50 ${
        !isOpen && 'opacity-0 z-[-9999]'
      }`}
    >
      <div
        className={`fixed inset-0 bg-black transition-opacity bg-opacity-50 ${
          !isOpen && 'opacity-0 z-[-10]'
        } z-40`}
        onClick={closeModal}
      />
      <div
        className={`fixed inset-x-0 bottom-0 transform transition-transform duration-300 ease-in-out ${
          isOpen ? 'translate-y-0' : 'translate-y-full'
        } bg-[#262743] rounded-t-xl z-50 p-6`}
      >
        {children}
      </div>
    </div>
  );
};

export default CardModal;
