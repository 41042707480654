export const translateFields = {
  'Rewards/Fees': 'Вознаграждения/Комиссии',
  ATM: 'Банкомат',
  'Darknet marketplace': 'Торговая площадка в Даркнете',
  'Darknet service': 'Сервис в Даркнете',
  'Illegal service': 'Незаконный сервис',
  Scam: 'Мошенничество',
  'Scam crypto exchange': 'Мошенническая крипто-биржа',
  Gambling: 'Азартные игр',
  'Stolen assets': 'Украденные активы',
  'Mixing service': 'Сервис по смешиванию',
  Ransom: 'Выкуп',
  'Exchange licensed': 'Лицензированная биржа',
  'Exchange unlicensed': 'Нелицензированная биржа',
  Miner: 'Майнер',
  'Online marketplace': 'Онлайн-рынок',
  'Online wallet': 'Онлайн-кошелек',
  'P2P Exchange licensed': 'Лицензированная P2P-биржа',
  'Payment system': 'Платежная система',
  Sanctions: 'Санкции',
  'Terrorism financing': 'Финансирование терроризма',
  'Decentralized exchange': 'Децентрализованная биржа',
  Undefined: 'undefined',
};
